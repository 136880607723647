import React from 'react'

import { Button, Theme, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { SxProps } from '@mui/system'
import Box from '@mui/material/Box'

import ImgIcon from '../icons/ImgIcon'
import { DEFAULT_TOKEN_SYMBOL } from '../../helpers/constants'
import { getTokenLogoURI } from '../../helpers/utilities'

interface BBoxProps {
  symbol: string
  logoEl: React.ReactNode | null
}

const ButtonBox = ({ symbol, logoEl }: BBoxProps): JSX.Element => (
  <Box
    m={0}
    p={0}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
  >
    {logoEl && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        mr={1}
      >
        {logoEl}
      </Box>
    )}
    {symbol}
  </Box>
)

interface TokenButtonProps {
  symbol?: string
  logoURI?: string
  disabled?: boolean
  onClick?(): void
  asEmpty?: boolean
  justify?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
}

export const TokenButton = (
  {
    symbol, logoURI, disabled = false, onClick, asEmpty, justify, variant, size,
  }: TokenButtonProps,
): JSX.Element => {
  const theme = useTheme()
  const styles: SxProps<Theme> = {
    textTransform: 'none',
  }
  if (justify) {
    styles.justifyContent = 'space-between'
  }

  if (disabled) {
    styles.color = `${theme.palette.secondary.main}!important`
  }

  const [logoEl, uSymbol] = (asEmpty)
    ? [logoURI && <ImgIcon src={logoURI} />, symbol ?? 'Select token']
    : [<ImgIcon src={logoURI ?? getTokenLogoURI(DEFAULT_TOKEN_SYMBOL)} />, symbol ?? DEFAULT_TOKEN_SYMBOL]

  return (
    <Button
      fullWidth
      color="secondary"
      startIcon={(!justify) ? logoEl : undefined}
      endIcon={!disabled ? <KeyboardArrowDownIcon /> : undefined}
      disabled={disabled}
      sx={styles}
      onClick={onClick}
      variant={variant}
      size={size}
    >
      <ButtonBox
        logoEl={(justify) ? logoEl : undefined}
        symbol={uSymbol}
      />
    </Button>
  )
}
