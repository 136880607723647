import { useMemo, useEffect } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'

import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColorizedButton } from './ColorizedButton'

import {
  getReversedDexDirection, getSelectedDexToken, resetDexAmounts, setDexSelectedAmount, TokenIndexer, TokenState,
} from '../../features/dex/dexSlice'

import { useAddLiquidity, useSmartQuote } from '../../hooks/dex'
import { getDexSettings } from '../../features/settings/settingsSlice'
import { ApproveButton, SmartConnectorWrapper } from '.'
import { createOrUpdatePool } from '../../features/wallet/liquiditySlice'
import { isWrapDirection, isWrapped, pathToBridgeCurrencies } from '../../hooks/dex/utils'
import { getChainData } from '../../helpers/utilities'
import { getNativeCurrency } from '../../hooks/utils'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const AddLiquidityButton = (props: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const token0 = useAppSelector(getSelectedDexToken(TokenIndexer.TOKEN0)) as TokenState
  const token1 = useAppSelector(getSelectedDexToken(TokenIndexer.TOKEN1)) as TokenState
  const { slippage, deadline } = useAppSelector(getDexSettings)
  const chain = getChainData(chainId)
  const contract = chain?.dex?.routerAddress

  // for reverse direction
  const { updateIndexer } = useAppSelector((state) => state.dex)
  const updateToken = useAppSelector((state) => {
    if (typeof updateIndexer === 'undefined') return undefined
    return state.dex[updateIndexer]
  })
  const reverseIndexer = getReversedDexDirection(updateIndexer)
  const reverseToken = useAppSelector((state) => {
    if (typeof reverseIndexer === 'undefined') return undefined
    return state.dex[reverseIndexer]
  })

  const isUnwrap = isWrapDirection(token0.currency, token1.currency)
  const isWrap = isWrapDirection(token1.currency, token0.currency)

  const { addLiquidity } = useAddLiquidity({
    chainId,
    to: account,
    deadline,
  })

  const { library: provider } = useWeb3React<Web3Provider>()

  const {
    amount: reversedAmount, error: quoteError, pair: pairAddress, isFetching, isLoading,
  } = useSmartQuote({
    provider,
    currency0: updateToken?.currency,
    currency1: reverseToken?.currency,
    amount: (updateToken as TokenState)?.selectedAmount,
  })

  useEffect(() => {
    if (
      typeof reverseIndexer === 'undefined'
      || typeof updateIndexer === 'undefined'
    ) return

    if (quoteError) {
      dispatch(setDexSelectedAmount({
        indexer: reverseIndexer,
      }))
      return
    }
    if (!isFetching && !isLoading && pairAddress !== ethers.constants.AddressZero) {
      dispatch(setDexSelectedAmount({
        indexer: reverseIndexer,
        amount: reversedAmount,
      }))
    }
  }, [dispatch, pairAddress, isFetching, isLoading, quoteError, reversedAmount, reverseIndexer, updateIndexer])

  const handleClick = async () => {
    if (
      !token0.currency
      || !token1.currency
      || !token0.approved
      || !token1.approved
      || !token0.selectedAmount
      || !token1.selectedAmount
      || token0.currency.address === token1.currency.address
    ) return

    const txHash = await addLiquidity({
      currency0: token0.currency,
      currency1: token1.currency,
      amount0Desired: token0.selectedAmount,
      amount1Desired: token1.selectedAmount,
      slippage,
    })
    if (txHash) {
      const currencies = pathToBridgeCurrencies(token0.currency, token1.currency)
      if (currencies) {
        const currency0 = isWrapped(currencies[0]) ? getNativeCurrency(currencies[0].chainId) : currencies[0]
        const currency1 = isWrapped(currencies[1]) ? getNativeCurrency(currencies[1].chainId) : currencies[1]
        dispatch(createOrUpdatePool({
          account,
          pairAddress: ethers.constants.AddressZero,
          lpPooled: ethers.constants.Zero,
          lpTotal: ethers.constants.Zero,
          lpShare: 0,
          currency0,
          pooled0: ethers.constants.Zero,
          currency1,
          pooled1: ethers.constants.Zero,
        }))
      }
      dispatch(resetDexAmounts())
    }
  }

  const { disabled, text, isFinall } = useMemo(() => {
    if (quoteError) {
      return {
        disabled: true,
        text: quoteError,
      }
    }
    if (
      (token0.currency && !token1.currency)
      || (token1.currency && !token0.currency)
      || (token0.currency?.address === token1.currency?.address)
      || isUnwrap
      || isWrap
    ) {
      return {
        disabled: true,
        text: 'Invalid pair',
      }
    }
    if (!token0.selectedAmount || !token1.selectedAmount) {
      return {
        disabled: true,
        text: 'Enter an amount',
      }
    }
    if (!token0.maxAmount || token0.selectedAmount?.gt(token0.maxAmount)) {
      return {
        disabled: true,
        text: `Insufficient ${token0.currency?.symbol} balance`,
      }
    }
    if (!token1.maxAmount || token1.selectedAmount?.gt(token1.maxAmount)) {
      return {
        disabled: true,
        text: `Insufficient ${token1.currency?.symbol} balance`,
      }
    }
    return {
      disabled: !(token0.approved && token1.approved),
      text: 'Add liquidity',
      isFinall: true,
    }
  }, [quoteError, token0, token1, isUnwrap, isWrap])

  return (
    <SmartConnectorWrapper variant="outlined" color="secondary" size="large">
      <>
        {isFinall && (
          <ButtonGroup fullWidth sx={{ marginBottom: 1 }}>
            <ApproveButton
              indexer={TokenIndexer.TOKEN0}
              contract={contract}
              {...props}
            />
            <ApproveButton
              indexer={TokenIndexer.TOKEN1}
              contract={contract}
              {...props}
            />
          </ButtonGroup>
        )}
        <ColorizedButton
          fullWidth
          disabled={disabled}
          onClick={handleClick}
          {...props}
        >
          {text}
        </ColorizedButton>
      </>
    </SmartConnectorWrapper>
  )
}

export default AddLiquidityButton
