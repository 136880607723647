import { useMemo } from 'react'
import { BigNumber, constants } from 'ethers'

import useGetPair from './useGetPair'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import { Currency } from '../../entities'

import IERC20ABI from '../../abi/IERC20.abi.json'
import { getDexPath, pathToBridgeCurrencies } from './utils'
import { MAX_PERCENT } from '../../helpers/constants'
import { HookProvider } from '../types'

interface PoolInfoRequest {
  provider?: HookProvider
  address: string
  currency0?: Currency
  currency1?: Currency
}

interface PoolInfoResponse {
  pairAddress?: string
  lpPooled?: BigNumber
  lpTotal?: BigNumber
  lpShare?: number
  currency0?: Currency
  pooled0?: BigNumber
  currency1?: Currency
  pooled1?: BigNumber

  isLoading: boolean
  isFetching: boolean
  isSkipping: boolean
}

const useGetPoolInfo = ({
  provider,
  address,
  currency0,
  currency1,
}: PoolInfoRequest): PoolInfoResponse => {
  const {
    address: pair,
    isLoading: isPairLoading,
    isFetching: isPairFetching,
  } = useGetPair({ provider, currency0, currency1 })

  const { path, weth9 } = getDexPath(currency0, currency1)

  const skip = (
    !pair
    || pair === constants.AddressZero
    || address === constants.AddressZero
    || !currency0
    || !currency1
    || currency0.address === currency1.address
    || !weth9
    || !provider
  )

  const { data, isLoading, isFetching } = useWeb3RequestQuery({
    provider,
    request: [
      {
        address: pair,
        abi: IERC20ABI,
        method: 'balanceOf',
        params: [address],
      },
      {
        address: pair,
        abi: IERC20ABI,
        method: 'totalSupply',
      },
      {
        address: path[0],
        abi: IERC20ABI,
        method: 'balanceOf',
        params: [pair],
      },
      {
        address: path[1],
        abi: IERC20ABI,
        method: 'balanceOf',
        params: [pair],
      },
    ],
  }, {
    skip,
    pollingInterval: 15_000,
  })
  const currencies = pathToBridgeCurrencies(currency0, currency1)

  const response = useMemo(() => {
    if (!data || !Array.isArray(data)) return undefined

    return data.map((value) => (
      value && BigNumber.from(value)
    )) as Array<BigNumber | undefined>
  }, [data])

  const infoResp: PoolInfoResponse = {
    pairAddress: (pair && pair !== constants.AddressZero) ? pair : undefined,
    isLoading: isLoading || isPairLoading,
    isFetching: isFetching || isPairFetching,
    isSkipping: (isPairLoading) ? false : skip,
  }

  if (!response || !currencies || !currency0 || !currency1) return infoResp

  const [lpPooled, lpTotal, balance0, balance1] = response

  const delta = (lpTotal) ? lpPooled?.mul(BigNumber.from(MAX_PERCENT)).div(lpTotal) : undefined
  const lpShare = +((delta?.toNumber() || 0) / 100).toFixed(12)

  infoResp.lpPooled = lpPooled
  infoResp.lpTotal = lpTotal
  infoResp.lpShare = lpShare

  if (!lpPooled || !lpTotal || !balance0 || !balance1) return infoResp

  const amount0 = lpPooled.mul(balance0).div(lpTotal)
  const amount1 = lpPooled.mul(balance1).div(lpTotal)

  if (currencies[0].address === currency0.address) {
    infoResp.pooled0 = amount0
    infoResp.currency0 = currency0
    infoResp.pooled1 = amount1
    infoResp.currency1 = currency1
  } else {
    infoResp.pooled0 = amount0
    infoResp.currency0 = currency1
    infoResp.pooled1 = amount1
    infoResp.currency1 = currency0
  }

  return infoResp
}

export default useGetPoolInfo
