import { Token, Currency } from '.'

import { Token as GqlToken } from '../graphs/dex'

export const getLpCurrency = (chainId: number, address: string): Currency => (
  new Token(
    chainId,
    address,
    18,
    'Kirka-LP',
    'Kirka LPs',
  )
)

export const getMegaPoolCurrency = (chainId: number, address: string): Currency => (
  new Token(
    chainId,
    address,
    18,
    'MegaPool',
    'MegaPool',
  )
)

export const getGqlCurrency = (chainId: number, token: GqlToken): Currency => (
  new Token(
    chainId,
    token.id.toUpperCase(),
    +token.decimals,
    token.symbol,
    token.name,
  )
)
