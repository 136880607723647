import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import {
  SwapButton,
} from '../../buttons'
import PageDex from './PageDex'

const Title = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
        ml={1}
      >
        <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>Swap</Typography>
        <Typography variant="body2">Trade tokens in an instant</Typography>
      </Box>
    </Box>
  )
}

const Swap = (): JSX.Element => (
  <PageDex separator="downArrow">
    <Title />
    <SwapButton />
  </PageDex>
)

export default Swap
