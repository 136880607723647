import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material'

import { BigNumberish } from '@ethersproject/bignumber'
import { getChainData, getTokenLogoURI, prettyFormat } from '../../helpers/utilities'
import { ImgIcon } from '../icons'
import { BridgeAccountInfo } from '../../features/bridge/bridgeSlice'

interface SwapInfoProps {
  loading: boolean
  accountInfo0: BridgeAccountInfo
  accountInfo1: BridgeAccountInfo
}

const BridgeInfoPaper = ({
  loading, accountInfo0, accountInfo1,
}: SwapInfoProps): JSX.Element | null => {
  const [expanded, setExpanded] = useState<string | boolean>(false)
  const theme = useTheme()

  const { currency: currency0, maxValue: balance0 } = accountInfo0
  const { currency: currency1, maxValue: balance1 } = accountInfo1

  if (!currency0 || !currency1 || !balance0 || !balance1) {
    return null
  }

  const chain0 = getChainData(currency0.chainId)
  const chain1 = getChainData(currency1.chainId)

  const fetching = loading

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (fetching) return
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box
      mt={4}
      sx={{
        width: '100%',
      }}
    >
      <Accordion
        expanded={(!fetching) ? expanded === 'swap' : false}
        onChange={handleChange('swap')}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          backgroundColor: (theme.palette.mode === 'light') ? theme.palette.secondary.main : theme.palette.primary.main,
          position: 'inherit',
          borderRadius: theme.spacing(1),
          '&:first-of-type': {
            borderRadius: theme.spacing(1),
          },
          '&:last-of-type': {
            borderRadius: theme.spacing(1),
          },
          marginBottom: 2,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="swapInfo-content"
          id="swapInfo-header"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {(fetching) ? (
              <>
                <CircularProgress color="secondary" size={14} sx={{ marginRight: 1 }} />
                <Typography variant="body2">
                  Fetching liquidity info...
                </Typography>
              </>
            ) : (
              <>
                <InfoOutlinedIcon sx={{ fontSize: 12, marginRight: 1 }} />
                <Typography variant="body2">
                  Liquidity info
                </Typography>
              </>
            )}
          </Box>
        </AccordionSummary>
        {!fetching && (
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb={1}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  mr={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ImgIcon src={getTokenLogoURI(currency0.symbol, true)} />
                </Box>
                <Typography
                  variant="body2"
                  sx={{ marginRight: 0.5, color: theme.palette.secondary.main }}
                >
                  {currency0.symbol}
                </Typography>
                <Typography variant="body2">
                  {`liquidity on ${chain0?.name}`}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.main }}
              >
                {balance0 ? prettyFormat(balance0 as BigNumberish, currency0.decimals) : '-'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb={1}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  mr={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ImgIcon src={getTokenLogoURI(currency1.symbol, true)} />
                </Box>
                <Typography
                  variant="body2"
                  sx={{ marginRight: 0.5, color: theme.palette.secondary.main }}
                >
                  {currency1.symbol}
                </Typography>
                <Typography variant="body2">
                  {`liquidity on ${chain1?.name}`}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.secondary.main }}
              >
                {balance1 ? prettyFormat(balance1 as BigNumberish, currency1.decimals) : '-'}
              </Typography>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  )
}

export default BridgeInfoPaper
