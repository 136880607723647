import { useState, useEffect } from 'react'

import { useTheme, Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import Grid from '@mui/material/Grid'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { ethers } from 'ethers'
import { BridgeInput } from '../inputs'
import {
  BridgeDirectionName, setDualDirection, swapDirection,
} from '../../features/bridge/bridgeSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks'

import { useFetchBridgeCurrencies } from '../../hooks'
import { getChainData } from '../../helpers/utilities'
import { BridgeSettingsButton, BridgeTransferButton, SmartConnectorWrapper } from '../buttons'
import { WarningPaper, FormPaper } from '../papers'
import { PageBox } from '../boxes'
import { DEFAULT_CHAIN_ID } from '../../helpers/constants'

const Bridge = (): JSX.Element => {
  const theme = useTheme()
  const [initialized, setInitialized] = useState(false)
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const {
    getDefaultCurrency, getLinkCurrency, isLoading,
  } = useFetchBridgeCurrencies()
  let chainData = getChainData(wallet.chainId)

  // in case no bridge
  if (chainData?.bridgeAddress === ethers.constants.AddressZero) {
    chainData = getChainData(DEFAULT_CHAIN_ID)
  }

  useEffect(() => {
    if (
      !chainData
      || !wallet.isAuthorized
      || isLoading
      || initialized
    ) return

    const sourceChainId = chainData.chainId
    const sourceCurrency = getDefaultCurrency(sourceChainId)

    const targetChainId = chainData.defaultTargetChainId
    const targetCurrency = getLinkCurrency(sourceChainId, targetChainId, sourceCurrency?.address)

    dispatch(setDualDirection({
      [BridgeDirectionName.SOURCE]: {
        chainId: sourceChainId,
        currency: sourceCurrency,
      },
      [BridgeDirectionName.TARGET]: {
        chainId: targetChainId,
        currency: targetCurrency,
      },
    }))
    setInitialized(true)
  }, [initialized, wallet, getDefaultCurrency, getLinkCurrency, chainData, isLoading, dispatch])

  const onSwapDirection = () => {
    dispatch(swapDirection())
  }

  return (
    <PageBox>
      <WarningPaper>
        <Grid container>
          <Grid item xs={1}>
            <InfoOutlinedIcon sx={{ width: 0.5, height: 0.5 }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              align="left"
            >
              Kirka Bridge is a new cross-chain protocol that is currently in a beta release phase.
              Please only bridge funds that you feel comfortable deploying in an experimental system.
            </Typography>
          </Grid>
        </Grid>
      </WarningPaper>
      <FormPaper>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>Bridge</Typography>
          <BridgeSettingsButton />
        </Box>
        <BridgeInput
          labelName="From"
          direction={BridgeDirectionName.SOURCE}
        />
        <Box p={1}>
          <Button
            aria-label="swap"
            color={theme.palette.mode === 'light' ? 'warning' : 'secondary'}
            onClick={onSwapDirection}
            endIcon={<SwapVertIcon />}
          >
            Switch
          </Button>
        </Box>
        <BridgeInput
          labelName="To"
          direction={BridgeDirectionName.TARGET}
        />
        <Box sx={{ marginTop: 4 }}>
          <SmartConnectorWrapper variant="outlined" color="secondary" size="large">
            <BridgeTransferButton />
          </SmartConnectorWrapper>
        </Box>
      </FormPaper>
    </PageBox>
  )
}

export default Bridge
