export const DEFAULT_CHAIN_ID = 71401
export const DEFAULT_TOKEN_SYMBOL = 'USDC'
export const BRIDGE_TOKEN_LIST_BASE_URI = 'https://raw.githubusercontent.com/kirkaswap/token-lists/master'
export const BRIDGE_BOWL_BASE_URI = 'https://api-bridge.kirkaswap.com/api/v1'
export const PERCENT_DENOMINATOR = 100
export const MAX_PERCENT = 10000
export const DEFAULT_PROJECT_NAME = 'kirkaswap'
export const POLLING_INTERVAL = 12000
export const DEFAULT_STAKING_TOKEN = 'KSG'
export const ANALYTICS_URI = 'https://info.kirkaswap.com'
// TODO
export const DEFAULT_GRAPH_NODE_URI = 'http://localhost:9999'
export const DEX_TOKEN_LIST_BASE_URI = 'https://raw.githubusercontent.com/kirkaswap/token-lists/master'
export const ADMIN_ORCHESTRATOR_GRAPH_URI = (
  process.env.REACT_APP_ADMIN_ORCHESTRATOR_GRAPH_URI
  || 'http://localhost:9999/graphql'
)
