import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { BigNumber } from 'ethers'
import { Currency } from '../../entities'
import { getTokenLogoURI, prettyFormat } from '../../helpers/utilities'
import ImgIcon from '../icons/ImgIcon'

interface LPHeaderBoxProps {
  lpPooled: BigNumber
  currency0: Currency
  currency1: Currency
  isMining?: boolean
}

const LPHeaderBox = ({
  lpPooled, currency0, currency1, isMining,
}: LPHeaderBoxProps): JSX.Element => {
  let lpPooledFmt = prettyFormat(lpPooled, 18).toString()
  if (lpPooledFmt === '0') {
    lpPooledFmt = '< 0.001'
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box mr={1}>
          <ImgIcon src={getTokenLogoURI(currency0.symbol, true)} />
        </Box>
        <Box mr={1}>
          <ImgIcon src={getTokenLogoURI(currency1.symbol, true)} />
        </Box>
        <Typography variant="body2">{`${currency0.symbol} - ${currency1.symbol} LP`}</Typography>
      </Box>
      {(!isMining) ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="secondary">{lpPooledFmt}</Typography>
        </Box>
      ) : (
        <CircularProgress color="secondary" size={14} />
      )}
    </Box>
  )
}

export default LPHeaderBox
