import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { BigNumber } from 'ethers'

import { Currency } from '../../entities'
import { getLpCurrency } from '../../entities/utils'
import { prettyFormat } from '../../helpers/utilities'
import { AddTokenButton } from '../buttons'

interface LPInfoBoxProps {
  pairAddress: string
  lpShare: number
  currency0: Currency
  pooled0: BigNumber
  currency1: Currency
  pooled1: BigNumber
}

const LPInfoBox = ({
  pairAddress,
  lpShare,
  currency0,
  pooled0,
  currency1,
  pooled1,
}: LPInfoBoxProps): JSX.Element => {
  const share = (lpShare > 0.01) ? lpShare.toFixed(2).toString() : '<0.01'
  const pairCurrency = getLpCurrency(currency0.chainId, pairAddress)
  return (
    <Box
      mt={1}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        mb={1}
      >
        <Typography variant="body2">Share of Pool:</Typography>
        <Typography variant="body2">{`${share} %`}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        mb={1}
      >
        <Typography variant="body2">{`Pooled ${currency0.symbol}:`}</Typography>
        <Typography variant="body2">{prettyFormat(pooled0, currency0.decimals)}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        mb={1}
      >
        <Typography variant="body2">{`Pooled ${currency1.symbol}:`}</Typography>
        <Typography variant="body2">{prettyFormat(pooled1, currency1.decimals)}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        mb={1}
      >
        <AddTokenButton
          currency={pairCurrency}
          fullWidth
        />
      </Box>
    </Box>
  )
}

export default LPInfoBox
