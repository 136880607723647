import { useEffect } from 'react'
import { ethers } from 'ethers'

import { useAppDispatch } from '../../app/hooks'
import { createOrUpdatePool, deletePool, PoolInfo } from '../../features/wallet/liquiditySlice'
import useGetPoolInfo from './useGetPoolInfo'
import { HookProvider } from '../types'
import { getNativeCurrency } from '../utils'
import { isWrapped } from './utils'

interface FetchPoolInfo {
  provider?: HookProvider
  address: string,
  pool: PoolInfo
}

const useFetchPoolInfo = ({
  provider, address, pool,
}: FetchPoolInfo): void => {
  const dispatch = useAppDispatch()
  const {
    pairAddress,
    lpPooled, lpTotal, lpShare, pooled0, pooled1,
    currency0: token0, currency1: token1,
    isSkipping, isFetching,
  } = useGetPoolInfo({
    provider,
    address,
    currency0: pool.currency0,
    currency1: pool.currency1,
  })

  const foundCurrency0 = (token0 && isWrapped(token0)) ? getNativeCurrency(token0.chainId) : token0
  const foundCurrency1 = (token1 && isWrapped(token1)) ? getNativeCurrency(token1.chainId) : token1

  const infoLoaded = (
    pairAddress && lpPooled && lpPooled.gt(ethers.constants.Zero)
    && typeof lpShare !== 'undefined' && pooled0 && pooled1 && foundCurrency0 && foundCurrency1 && lpTotal
    && foundCurrency0.address !== foundCurrency1.address
  )

  useEffect(() => {
    if (
      (
        pool.pairAddress !== ethers.constants.AddressZero
        && !isSkipping
        && !isFetching
        && (
          !lpPooled
          || lpPooled.eq(ethers.constants.Zero)
        )
      ) || (
        pool.account !== address
        && address !== ethers.constants.AddressZero
      )
    ) {
      dispatch(deletePool({
        account: address,
        pairAddress: pool.pairAddress,
      }))
    }
  }, [
    dispatch, address, isSkipping, isFetching, pairAddress, lpPooled,
    pool.account, pool.pairAddress,
  ])

  useEffect(() => {
    if (!infoLoaded || typeof lpShare === 'undefined') return

    dispatch(createOrUpdatePool({
      account: address,
      pairAddress,
      lpPooled,
      lpTotal,
      lpShare,
      currency0: foundCurrency0,
      pooled0,
      currency1: foundCurrency1,
      pooled1,
    }))
  }, [
    dispatch,
    address,
    infoLoaded,
    pairAddress,
    lpPooled,
    lpTotal,
    lpShare,
    foundCurrency0,
    pooled0,
    foundCurrency1,
    pooled1,
  ])
}

export default useFetchPoolInfo
