import { BigNumber, constants } from 'ethers'

import { Currency } from '../../entities'
import useGetPair from './useGetPair'
import useGetReserves from './useGetReserves'
import { quote } from '../../helpers/utilities'
import { pathToBridgeCurrencies } from './utils'
import { HookProvider } from '../types'

interface SmartQuoteData {
  provider?: HookProvider
  currency0?: Currency
  currency1?: Currency
  amount?: BigNumber
}

interface SmartQuoteResponse {
  pair?: string
  amount?: BigNumber
  error?: string
  isFetching: boolean
  isLoading: boolean
}

const useSmartQuote = ({
  provider,
  currency0,
  currency1,
  amount,
}: SmartQuoteData): SmartQuoteResponse => {
  const {
    address: pair,
    isFetching: isPairFetching,
    isLoading: isPairLoading,
  } = useGetPair({
    provider,
    currency0,
    currency1,
  })

  const {
    reserves,
    isFetching: isReservesFetching,
    isLoading: isReservesLoading,
  } = useGetReserves({ provider, pair })

  const isFetching = isPairFetching || isReservesFetching
  const isLoading = isPairLoading || isReservesLoading

  if (!currency0 || !currency1 || !amount || !reserves) {
    return {
      isFetching,
      isLoading,
      pair,
    }
  }

  const currencies = pathToBridgeCurrencies(currency0, currency1)
  if (!currencies) {
    return {
      error: 'Wrong currencies',
      isFetching,
      isLoading,
      pair,
    }
  }

  let [reserve0, reserve1] = [constants.Zero, constants.Zero]
  if (currencies[0].equals(currency0)) {
    reserve0 = reserves.reserve0
    reserve1 = reserves.reserve1
  } else {
    reserve0 = reserves.reserve1
    reserve1 = reserves.reserve0
  }

  try {
    const quotedAmount = quote(amount, reserve0, reserve1)
    return {
      amount: quotedAmount,
      error: quotedAmount.eq(constants.Zero) ? 'Insufficient amount' : undefined,
      isFetching: isPairFetching || isReservesFetching,
      isLoading: isPairLoading || isReservesLoading,
      pair,
    }
  } catch (e) {
    return {
      error: (e as Error).message,
      isFetching: isPairFetching || isReservesFetching,
      isLoading: isPairLoading || isReservesLoading,
      pair,
    }
  }
}

export default useSmartQuote
