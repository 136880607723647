import React, { useEffect, useMemo } from 'react'
import { Typography, CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'

import { ethers, BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from '../../app/hooks'
import { prettyFormat } from '../../helpers/utilities'
import {
  DexMaxButton,
} from '../buttons'
import { useFetchBalancePerCurrencies } from '../../hooks'
import {
  setDexMaxAmount, TokenIndexer,
} from '../../features/dex/dexSlice'
import { Currency } from '../../entities'

interface BalanceProps {
  label?: string
  account: string
  indexer: TokenIndexer
  currency: Currency
  showMax?: boolean
  showSymbol?: boolean
  rounding?: number
  // the max limit amount from which detected max amount should be withdrawed
  maxLimitAmount?: BigNumber
  onBalanceUpdate?(balance: BigNumber): void
}

const AccountBalance = ({
  label, account, maxLimitAmount, indexer, currency, showMax,
  showSymbol, rounding = 8, onBalanceUpdate,
}: BalanceProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { library: provider } = useWeb3React<Web3Provider>()

  const {
    data, isLoading, isFetching,
  } = useFetchBalancePerCurrencies({
    provider,
    fetchInfo: {
      address: account,
      currency,
    },
    pollingInterval: 5_000,
  })

  const balance = useMemo(() => {
    let amount = Array.isArray(data) ? data[0] : data
    amount = amount || ethers.constants.Zero
    if (maxLimitAmount && maxLimitAmount.gt(ethers.constants.Zero)) {
      amount = maxLimitAmount.sub(amount)
    }
    return amount
  }, [data, maxLimitAmount])

  // set direction balance for transfer purposes in global state
  useEffect(() => {
    if (onBalanceUpdate) {
      onBalanceUpdate(balance)
    } else {
      dispatch(setDexMaxAmount({
        indexer,
        amount: balance,
      }))
    }
  }, [dispatch, onBalanceUpdate, indexer, balance])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {label && (
          <Typography
            variant="body2"
            color="secondary"
            noWrap
            sx={{ marginRight: 0.5, fontSize: 12 }}
          >
            {label}
          </Typography>
        )}
        {(isLoading) ? (
          <Typography
            variant="body2"
            color="secondary"
            noWrap
            sx={{ marginRight: 1, fontSize: 12 }}
          >
            <CircularProgress
              disableShrink
              color="secondary"
              size={10}
            />
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="secondary"
            noWrap
            sx={{ marginRight: 0.5, fontSize: 12 }}
          >
            {
              balance
                ? prettyFormat(balance, currency.decimals, rounding)
                : 0
            }
          </Typography>
        )}
        {showSymbol && (
          <Typography
            variant="body2"
            color="secondary"
            noWrap
            sx={{ fontWeight: 600, fontSize: 12 }}
          >
            {currency.symbol}
          </Typography>
        )}
      </Box>
      {showMax && (
        <DexMaxButton
          indexer={indexer}
          isLoading={isLoading || isFetching}
          variant="outlined"
          color="error"
          size="small"
          sx={{
            minWidth: 24,
            marginLeft: 1,
            fontSize: '.7rem',
          }}
        />
      )}
    </>
  )
}

export default AccountBalance
