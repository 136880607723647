import { SxProps, Theme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { ethers } from 'ethers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { setValue } from '../../features/bridge/bridgeSlice'

interface ButtonProps {
  isLoading?: boolean
  sx?: SxProps<Theme>
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

export const SafeMaxButton = ({ isLoading, ...restProps }: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const value = useAppSelector((state) => state.bridge.balance)

  const disabled = isLoading || value.eq(ethers.constants.Zero)

  const addMax = async () => {
    if (disabled) return
    dispatch(setValue(value))
  }

  return (
    <Button
      onClick={addMax}
      disabled={disabled}
      {...restProps}
    >
      Max
    </Button>
  )
}
