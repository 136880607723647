import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'

interface AmountSliderProps {
  percentage: number
  setPercentage: React.Dispatch<React.SetStateAction<number>>
}

const AmountSliderPaper = ({
  percentage, setPercentage,
}: AmountSliderProps): JSX.Element => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    setPercentage(newValue as number)
  }

  const valuetext = (value: number) => `${value} %`

  const marks = [
    {
      value: 0,
      label: '0 %',
    },
    {
      value: 25,
      label: '25 %',
    },
    {
      value: 50,
      label: '50 %',
    },
    {
      value: 75,
      label: '75 %',
    },
    {
      value: 100,
      label: 'Max',
    },
  ]

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 2,
        marginBottom: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
        p={1}
        pl={2}
        pr={2}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          mb={1}
        >
          <Typography variant="body2" color="secondary">Amount</Typography>
        </Box>
        <Box>
          <Slider
            aria-label="Amount info slider"
            value={percentage}
            onChange={handleChange}
            getAriaValueText={valuetext}
            step={5}
            marks={marks}
            valueLabelDisplay="on"
          />
        </Box>
      </Box>
    </Paper>
  )
}

export default AmountSliderPaper
