import { BigNumber } from 'ethers'

import { Currency } from '../../entities'

import IKirkaRouterABI from '../../abi/IKirkaRouter.abi.json'
import { useWeb3RequestQuery } from '../../features/api/web3ApiSlice'
import { getDexPath, pathToBridgeCurrencies } from './utils'
import { HookProvider } from '../types'
import { TokenIndexer } from '../../features/dex/dexSlice'

interface GetData {
  provider?: HookProvider
  currency0?: Currency
  currency1?: Currency
  amount?: BigNumber
  direction?: TokenIndexer
  isWrap?: boolean
}

interface AmountsResponse {
  amount?: BigNumber
  error?: string
  isFetching: boolean
  isLoading: boolean
}

const useGetAmounts = ({
  provider, currency0, currency1, amount, direction, isWrap,
}: GetData): AmountsResponse => {
  const { chain, weth9 } = getDexPath(currency0, currency1)

  // eslint-disable-next-line no-nested-ternary
  let path = (currency0?.isNative)
    ? [weth9?.address, currency1?.address]
    : (
      (currency1?.isNative)
        ? [currency0?.address, weth9?.address]
        : [currency0?.address, currency1?.address]
    )

  let method = ''

  switch (direction) {
    case TokenIndexer.TOKEN0:
      method = 'getAmountsOut'
      break
    case TokenIndexer.TOKEN1:
      method = 'getAmountsIn'
      // TODO: Refactor this?
      path = [path[1], path[0]]
      break
    // no default
  }

  const skip = (
    !currency0?.address
    || !currency1?.address
    || currency0.address === currency1.address
    || !amount
    || !weth9
    || !chain?.dex?.routerAddress
    || !method
    || !direction
    || isWrap
    || !provider
  )

  // if (!skip) {
  //   console.log('direction', direction)
  //   console.log('path', path)
  //   console.log('amount', amount?.toString())
  // }

  const { data: amountsData, ...amountsQueryData } = useWeb3RequestQuery({
    provider,
    request: {
      address: chain?.dex?.routerAddress,
      abi: IKirkaRouterABI,
      method,
      params: [amount?.toString(), path],
    },
  }, {
    skip,
    pollingInterval: 30_000,
  })

  const amounts = amountsData ? amountsData as [BigNumber, BigNumber] : [undefined, undefined]

  const infoResp: AmountsResponse = {
    isLoading: amountsQueryData.isLoading,
    isFetching: amountsQueryData.isFetching,
  }

  if (isWrap) {
    infoResp.amount = amount
    return infoResp
  }

  if (!currency0 || !currency1 || !weth9 || skip || !provider) return infoResp

  const currencies = pathToBridgeCurrencies(currency0, currency1)
  if (!currencies) return infoResp

  const [amount0, amount1] = amounts

  // if (!skip) {
  //   console.log('method', method)
  //   console.log('amount0', amount0?.toString())
  //   console.log('amount1', amount1?.toString())
  // }

  if (
    !infoResp.isFetching
    && !infoResp.isLoading
    && typeof amount0 === 'undefined'
    && typeof amount1 === 'undefined'
  ) {
    infoResp.error = 'Insufficient liquidity for this trade'
    return infoResp
  }

  if (method === 'getAmountsOut') {
    infoResp.amount = amount1
  } else {
    infoResp.amount = amount0
  }

  return infoResp
}

export default useGetAmounts
