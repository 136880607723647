import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'

import { ethers } from 'ethers'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppSelector, useAppDispatch } from '../../app/hooks'

import {
  ColorizedButton, SmartConnectorWrapper,
} from '../buttons'
import { LPHeaderBox, LPInfoBox } from '../boxes'
import { Currency } from '../../entities'
import { useGetPoolInfo } from '../../hooks/dex'
import { createOrUpdatePool } from '../../features/wallet/liquiditySlice'
import { isWrapped } from '../../hooks/dex/utils'
import { getNativeCurrency } from '../../hooks/utils'

interface PoolInfoProps {
  currency0?: Currency
  currency1?: Currency
  showPool?: boolean
}

const PoolInfoPaper = ({
  currency0, currency1, showPool,
}: PoolInfoProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const { library: provider } = useWeb3React<Web3Provider>()

  const {
    pairAddress,
    lpPooled, lpTotal, lpShare, pooled0, pooled1,
    currency0: token0, currency1: token1,
    isLoading,
  } = useGetPoolInfo({
    provider,
    address: wallet.account,
    currency0,
    currency1,
  })

  const foundCurrency0 = (token0 && isWrapped(token0)) ? getNativeCurrency(token0.chainId) : token0
  const foundCurrency1 = (token1 && isWrapped(token1)) ? getNativeCurrency(token1.chainId) : token1

  const infoLoaded = (
    pairAddress && lpPooled && lpPooled.gt(ethers.constants.Zero)
    && typeof lpShare !== 'undefined' && pooled0 && pooled1 && foundCurrency0 && foundCurrency1 && lpTotal
    && foundCurrency0.address !== foundCurrency1.address
  )

  useEffect(() => {
    if (!infoLoaded || typeof lpShare === 'undefined') return

    dispatch(createOrUpdatePool({
      account: wallet.account,
      pairAddress,
      lpPooled,
      lpTotal,
      lpShare,
      currency0: foundCurrency0,
      pooled0,
      currency1: foundCurrency1,
      pooled1,
    }))
  }, [
    dispatch,
    wallet,
    infoLoaded,
    pairAddress,
    lpPooled,
    lpTotal,
    lpShare,
    foundCurrency0,
    pooled0,
    foundCurrency1,
    pooled1,
  ])

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 2,
        marginBottom: 2,
      }}
    >
      <Box p={2} sx={{ width: '100%' }}>
        <SmartConnectorWrapper
          checkDex
          variant="outlined"
          color="secondary"
          size="large"
        >
          {(!currency0 || !currency1) ? (
            <Typography variant="body2" gutterBottom>Select a tokens to find your liquidity</Typography>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {(isLoading) && (
                <CircularProgress color="secondary" />
              )}
              {((!isLoading) && !!infoLoaded && typeof lpShare !== 'undefined') && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ marginBottom: 2 }}
                    color="secondary"
                  >
                    LP tokens in your wallet
                  </Typography>
                  <LPHeaderBox
                    lpPooled={lpPooled}
                    currency0={foundCurrency0}
                    currency1={foundCurrency1}
                  />
                  <LPInfoBox
                    pairAddress={pairAddress}
                    lpShare={lpShare}
                    currency0={foundCurrency0}
                    pooled0={pooled0}
                    currency1={foundCurrency1}
                    pooled1={pooled1}
                  />
                  {showPool && (
                    <ColorizedButton variant="outlined" href="#/pool">
                      Manage this pool
                    </ColorizedButton>
                  )}
                </>
              )}
              {((!isLoading) && !infoLoaded) && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {(currency0.address === currency1.address) ? (
                    <Typography variant="body2">
                      Invalid pair for liquidity
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body2" sx={{ marginBottom: 2 }}>
                        You don`t have liquidity in this pool yet
                      </Typography>
                      <ColorizedButton variant="outlined" href="#/add">
                        Add Liquidity
                      </ColorizedButton>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </SmartConnectorWrapper>
      </Box>
    </Paper>
  )
}

export default PoolInfoPaper
