import React, { useMemo, useEffect, useState } from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'

import { ethers, BigNumber } from 'ethers'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColorizedButton } from './ColorizedButton'

import {
  getReversedDexDirection, getSelectedDexToken, resetDexAmounts, setDexSelectedAmount, TokenIndexer, TokenState,
} from '../../features/dex/dexSlice'

import { useGetAmounts, usePriceImpact, useSwap } from '../../hooks/dex'
import { getDexSettings } from '../../features/settings/settingsSlice'
import { ApproveButton, SmartConnectorWrapper } from '.'
import {
  calculateAmountWithSlippage, hasPriceSeverifyWarning,
  isWrapDirection,
  PRICE_IMPACT_SEVERITY_DANGER, PRICE_IMPACT_SEVERITY_WARNING, SlippageType,
} from '../../hooks/dex/utils'
import { getChainData, prettyFormat } from '../../helpers/utilities'
import { Currency } from '../../entities'
import { PERCENT_DENOMINATOR } from '../../helpers/constants'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

interface SwapInfoProps {
  priceImpact: number
  swapIndexer: TokenIndexer
  loading: boolean
  token0: TokenState
  token1: TokenState
}

const SwapInfoBox = ({
  priceImpact, swapIndexer, loading, token0, token1,
}: SwapInfoProps): JSX.Element => {
  const [expanded, setExpanded] = useState<string | boolean>(false)
  const theme = useTheme()
  const { slippage } = useAppSelector(getDexSettings)

  const sInfo: {
    inputAmountPerOne: number | null,
    expectedToken?: {
      amount: BigNumber,
      currency: Currency,
      withSlippage: BigNumber,
      label: string,
      outputAmount: BigNumber,
      outputCurrency: Currency,
    },
    priceImpact: number,
  } = {
    inputAmountPerOne: null,
    expectedToken: undefined,
    priceImpact,
  }
  if (token0.currency && token1.currency && token0.selectedAmount && token1.selectedAmount) {
    sInfo.inputAmountPerOne = prettyFormat(token0.selectedAmount, token0.currency?.decimals)
      / prettyFormat(token1.selectedAmount, token1.currency?.decimals)
    sInfo.expectedToken = (swapIndexer === TokenIndexer.TOKEN0)
      ? {
        amount: token1.selectedAmount,
        currency: token1.currency,
        withSlippage: calculateAmountWithSlippage(token1.selectedAmount, slippage),
        label: 'Minimum received',
        outputAmount: token1.selectedAmount,
        outputCurrency: token1.currency,
      }
      : {
        amount: token0.selectedAmount,
        currency: token0.currency,
        withSlippage: calculateAmountWithSlippage(token0.selectedAmount, slippage, SlippageType.MAX),
        label: 'Maximum sent',
        outputAmount: token1.selectedAmount,
        outputCurrency: token1.currency,
      }
  }

  const fetching = loading

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (fetching) return
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box
      mb={4}
      sx={{
        width: '100%',
      }}
    >
      <Accordion
        expanded={(!fetching) ? expanded === 'swap' : false}
        onChange={handleChange('swap')}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          backgroundColor: (theme.palette.mode === 'light') ? theme.palette.secondary.main : theme.palette.primary.main,
          position: 'inherit',
          borderRadius: theme.spacing(1),
          '&:first-of-type': {
            borderRadius: theme.spacing(1),
          },
          '&:last-of-type': {
            borderRadius: theme.spacing(1),
          },
          marginBottom: 2,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="swapInfo-content"
          id="swapInfo-header"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {(sInfo.inputAmountPerOne === null || fetching) ? (
              <>
                <CircularProgress color="secondary" size={14} sx={{ marginRight: 1 }} />
                <Typography variant="body2">
                  Fetching best price...
                </Typography>
              </>
            ) : (
              <>
                <InfoOutlinedIcon sx={{ fontSize: 12, marginRight: 1 }} />
                <Typography variant="body2">
                  {`1 ${token1.currency?.symbol} = ${+sInfo.inputAmountPerOne.toFixed(8)} ${token0.currency?.symbol}`}
                </Typography>
              </>
            )}
          </Box>
        </AccordionSummary>
        {sInfo.expectedToken && !fetching && (
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb={1}
            >
              <Typography variant="body2">Expected Output</Typography>
              <Typography variant="body2">
                {`${prettyFormat(sInfo.expectedToken.outputAmount, sInfo.expectedToken.outputCurrency.decimals)}`}
                {` ${sInfo.expectedToken?.outputCurrency.symbol}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb={1}
            >
              <Typography variant="body2">Price Impact</Typography>
              <Typography
                variant="body2"
                sx={{
                  // eslint-disable-next-line no-nested-ternary
                  color: hasPriceSeverifyWarning(priceImpact, PRICE_IMPACT_SEVERITY_WARNING)
                    ? hasPriceSeverifyWarning(priceImpact, PRICE_IMPACT_SEVERITY_DANGER) ? 'red' : 'orange'
                    : 'inherit',
                }}
              >
                {`${sInfo.priceImpact} %`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb={1}
            >
              <Typography variant="body2">
                {`${sInfo.expectedToken.label} after slippage (${slippage / PERCENT_DENOMINATOR}%)`}
              </Typography>
              <Typography variant="body2">
                {`${prettyFormat(sInfo.expectedToken.withSlippage, sInfo.expectedToken.currency.decimals)}`}
                {` ${sInfo.expectedToken?.currency.symbol}`}
              </Typography>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  )
}

const SwapButton = (props: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const token0 = useAppSelector(getSelectedDexToken(TokenIndexer.TOKEN0)) as TokenState
  const token1 = useAppSelector(getSelectedDexToken(TokenIndexer.TOKEN1)) as TokenState
  const { slippage, deadline } = useAppSelector(getDexSettings)
  const chain = getChainData(chainId)
  const contract = chain?.dex?.routerAddress

  const [error, setError] = useState<string | undefined>()
  const [swapIndexer, setSwapIndexer] = useState<TokenIndexer | undefined>()

  // in case of slippage changing for refresh
  const [tempSlippage, setTempSlippage] = useState(slippage)

  const { library: provider } = useWeb3React<Web3Provider>()

  useEffect(() => {
    if (
      tempSlippage === slippage
      || !swapIndexer
      || !token0.selectedAmount
      || !token1.selectedAmount
    ) return

    console.log('refresh data with new slippage', slippage / PERCENT_DENOMINATOR)
    setTempSlippage(slippage)
    dispatch(setDexSelectedAmount({
      indexer: swapIndexer,
      amount: (swapIndexer === TokenIndexer.TOKEN0) ? token0.selectedAmount : token1.selectedAmount,
      update: true,
      force: true,
    }))
  }, [dispatch, swapIndexer, slippage, tempSlippage, token0.selectedAmount, token1.selectedAmount])

  const { priceImpact, isLoading: isPriceImpactLoading } = usePriceImpact({
    provider,
    currency0: token0.currency,
    currency1: token1.currency,
    amount0: token0.selectedAmount,
    amount1: token1.selectedAmount,
  })

  // for reverse direction
  const { updateIndexer } = useAppSelector((state) => state.dex)
  const updateToken = useAppSelector((state) => {
    if (typeof updateIndexer === 'undefined') return undefined
    return state.dex[updateIndexer]
  })
  const reverseIndexer = getReversedDexDirection(updateIndexer)
  const reverseToken = useAppSelector((state) => {
    if (typeof reverseIndexer === 'undefined') return undefined
    return state.dex[reverseIndexer]
  })

  const isUnwrap = isWrapDirection(token0.currency, token1.currency)
  const isWrap = isWrapDirection(token1.currency, token0.currency)

  const { swap, isLoading: isSwapLoading } = useSwap({
    provider,
    chainId,
    to: account,
    deadline,
  })

  const {
    amount: reversedAmount, error: getAmountsError, isFetching, isLoading,
  } = useGetAmounts({
    provider,
    currency0: updateToken?.currency,
    currency1: reverseToken?.currency,
    amount: (updateToken as TokenState)?.selectedAmount,
    direction: updateIndexer,
    isWrap: (isUnwrap || isWrap),
  })

  // TODO: Refactor this
  useEffect(() => {
    if (
      typeof reverseIndexer === 'undefined'
      || typeof updateIndexer === 'undefined'
    ) return

    if (getAmountsError) {
      console.log('error', getAmountsError)
      dispatch(setDexSelectedAmount({
        indexer: reverseIndexer,
      }))
      setError(getAmountsError)
      return
    }

    if (!isFetching && !isLoading) {
      setSwapIndexer(updateIndexer)
      dispatch(setDexSelectedAmount({
        indexer: reverseIndexer,
        amount: reversedAmount,
      }))
      setError(undefined)
    }
  }, [
    dispatch,
    slippage,
    getAmountsError,
    reversedAmount,
    updateIndexer,
    reverseIndexer,
    isFetching,
    isLoading,
    setError,
  ])

  const handleClick = async () => {
    if (
      !token0.currency
      || !token1.currency
      || (!token0.approved && !isUnwrap)
      || !token0.selectedAmount
      || !token1.selectedAmount
      || token0.currency.address === token1.currency.address
      || !swapIndexer
    ) return

    const amountIn = (swapIndexer === TokenIndexer.TOKEN1 && !isWrap && !isUnwrap)
      ? calculateAmountWithSlippage(token0.selectedAmount, slippage, SlippageType.MAX)
      : token0.selectedAmount
    const amountOut = (swapIndexer === TokenIndexer.TOKEN0 && !isWrap && !isUnwrap)
      ? calculateAmountWithSlippage(token1.selectedAmount, slippage)
      : token1.selectedAmount

    const txHash = await swap({
      currency0: token0.currency,
      currency1: token1.currency,
      amountIn,
      amountOut,
      direction: swapIndexer,
    })
    if (txHash) {
      setSwapIndexer(undefined)
      dispatch(resetDexAmounts())
    }
  }

  const isSwapAnyway = hasPriceSeverifyWarning(priceImpact, PRICE_IMPACT_SEVERITY_WARNING) && token0.approved

  const { disabled, text, isFinall } = useMemo(() => {
    // eslint-disable-next-line no-debugger
    if (error) {
      return {
        disabled: true,
        text: error,
      }
    }
    if (
      (token0.currency && !token1.currency)
      || (token1.currency && !token0.currency)
      || (token0.currency?.address === token1.currency?.address)
    ) {
      return {
        disabled: true,
        text: 'Invalid pair',
      }
    }
    if (!token0.selectedAmount || !token1.selectedAmount) {
      return {
        disabled: true,
        text: 'Enter an amount',
      }
    }
    if (
      (token0.selectedAmount.gt(ethers.constants.Zero) && token1.selectedAmount.eq(ethers.constants.Zero))
      || (token1.selectedAmount.gt(ethers.constants.Zero) && token0.selectedAmount.eq(ethers.constants.Zero))
    ) {
      return {
        disabled: true,
        text: 'Slippage too high',
      }
    }
    if (!token0.maxAmount || token0.selectedAmount?.gt(token0.maxAmount)) {
      return {
        disabled: true,
        text: `Insufficient ${token0.currency?.symbol} balance`,
      }
    }
    let displayText = (isSwapAnyway) ? 'Swap Anyway' : 'Swap'
    if (isUnwrap) {
      displayText = 'Unwrap'
    }
    if (isWrap) {
      displayText = 'Wrap'
    }
    return {
      disabled: !token0.approved && !isUnwrap,
      text: displayText,
      isFinall: !(isUnwrap),
    }
  }, [isSwapAnyway, error, isUnwrap, isWrap, token0, token1])

  return (
    <SmartConnectorWrapper variant="outlined" color="secondary" size="large">
      <>
        {!isUnwrap && !isWrap && token0.selectedAmount && token1.selectedAmount && swapIndexer && (
          <SwapInfoBox
            swapIndexer={swapIndexer}
            priceImpact={priceImpact}
            loading={isLoading || isFetching || isPriceImpactLoading}
            token0={token0}
            token1={token1}
          />
        )}
        {isFinall && (
          <ApproveButton
            indexer={TokenIndexer.TOKEN0}
            contract={contract}
            fullWidth
            sx={{ marginBottom: 1 }}
            {...props}
          />
        )}
        <ColorizedButton
          fullWidth
          loading={isLoading || isFetching || isSwapLoading}
          disabled={disabled}
          onClick={handleClick}
          sx={(isSwapAnyway) ? {
            color: 'red',
            borderColor: 'red',
            '&:hover': {
              borderColor: 'red',
            },
          } : undefined}
          {...props}
        >
          {text}
        </ColorizedButton>
      </>
    </SmartConnectorWrapper>
  )
}

export default SwapButton
