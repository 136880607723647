import { useState, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'

import { useAppDispatch, useAppSelector } from '../app/hooks'
import useFetchMetamaskAPI from './useFetchMetamaskAPI'
import { sleep } from '../helpers/utilities'
import IERC20ABI from '../abi/IERC20.abi.json'
import useWatchTx from './useWatchTx'
import {
  getLocalTxByFilter, initializeTx, isBridgeTx, LocalTxStatus, updateLocalTxByHash,
} from '../features/wallet/transactionSlice'
import { HookProvider } from './types'

interface ApproveData {
  provider?: HookProvider
  chainId: number
  account: string
  contract?: string
  addressToApprove?: string
}

interface ApproveAction {
  approve(selectedChainId: number): Promise<void>
  isLoading: boolean
  isApproved: boolean
}

const useERC20Approve = ({
  provider, chainId, account, contract, addressToApprove,
}: ApproveData): ApproveAction => {
  const dispatch = useAppDispatch()
  const [isLocked, setLock] = useState(false)
  const [currentTxHash, setTxHash] = useState<string | undefined>()

  const { switchNetwork, callContract } = useFetchMetamaskAPI(provider)

  const txFilter = useMemo(() => ([{
    from: account,
    to: contract,
    status: LocalTxStatus.PENDING,
    method: 'approve',
  }]), [account, contract])
  const currentState = useAppSelector(getLocalTxByFilter(txFilter))

  // hook to check for current selected contract, in case of another, reset
  useEffect(() => {
    if (!contract) return

    if (!currentState && currentTxHash) {
      setTxHash(undefined)
    }
    if (currentState && currentState.txHash && currentTxHash !== currentState.txHash) {
      setTxHash(currentState.txHash)
    }
  }, [currentState, currentTxHash, account, contract])

  useWatchTx({
    provider,
    txHash: isBridgeTx(currentState) ? undefined : currentState?.txHash,
    confirmations: isBridgeTx(currentState) ? undefined : currentState?.confirmations,
    onSuccess: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.SUCCESS }))
      setTxHash(undefined)
    },
    onError: (receipt, confirmations) => {
      dispatch(updateLocalTxByHash({ txHash: receipt.transactionHash, confirmations, status: LocalTxStatus.FAIL }))
      setTxHash(undefined)
    },
  })

  const approve = async (selectedChainId: number): Promise<void> => {
    if (!contract) return

    setLock(true)

    if (chainId !== selectedChainId) {
      const success = await switchNetwork(selectedChainId)
      if (!success) {
        setLock(false)
        return
      }
      await sleep(3000)
    }

    const txHash = await callContract({
      from: account,
      contract: {
        address: contract,
        abi: IERC20ABI,
        method: 'approve',
        params: [addressToApprove, ethers.constants.MaxUint256],
      },
    })
    if (txHash) {
      setTxHash(txHash)
      dispatch(initializeTx({
        txHash,
        chainId: selectedChainId,
        from: account,
        to: contract,
        value: ethers.constants.Zero,
        status: LocalTxStatus.PENDING,
        abi: IERC20ABI,
        method: 'approve',
        params: [addressToApprove, ethers.constants.MaxUint256],
        store: {
          update: true,
        },
      }))
    }
    setLock(false)
  }

  // in case tx hash was changed
  const isTimeout = (currentState) ? currentState.createdAt <= Math.floor(Date.now()) - 60 * 10 : false

  return {
    approve,
    isApproved: (!isTimeout) ? currentState?.status === LocalTxStatus.SUCCESS : true,
    isLoading: isLocked || currentState?.status === LocalTxStatus.PENDING,
  }
}

export default useERC20Approve
