import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton, useTheme } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { BigNumber } from 'ethers'

import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import {
  DexSettingsButton, RemoveLiquidityButton, SmartConnectorWrapper,
} from '../../buttons'
import PageWrapper from './PageWrapper'
import { useFetchDexCurrencies } from '../../../hooks'
import {
  setBaseLiquidityData,
  setUpdateLiquidityData,
} from '../../../features/dex/dexSlice'
import { getChainData } from '../../../helpers/utilities'
import { Currency, Token } from '../../../entities'
import { getPoolInfoByCurrencies } from '../../../features/wallet/liquiditySlice'
import {
  ReceiveInfoPaper, PriceInfoPaper, PoolInfoPaper, AmountSliderPaper,
} from '../../papers'
import { MAX_PERCENT } from '../../../helpers/constants'

interface TitleProps {
  currency0?: Currency
  currency1?: Currency
}

const Title = ({
  currency0,
  currency1,
}: TitleProps): JSX.Element => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <IconButton color="secondary" sx={{ width: 50 }} href="#/pool">
        <ArrowBackIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
        ml={1}
      >
        <Typography
          variant="h6"
          sx={{ color: theme.palette.secondary.main }}
        >
          {`Remove ${currency0?.symbol} - ${currency1?.symbol} liquidity`}
        </Typography>
        <Typography variant="body2">
          {`To receive ${currency0?.symbol} and ${currency1?.symbol}`}
        </Typography>
      </Box>
    </Box>
  )
}

const RemoveLiquidity = (): JSX.Element => {
  const { token0, token1 } = useParams()
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const [percentage, setPercentage] = useState<number>(0)

  const chain = getChainData(wallet.chainId)
  const { getCurrency } = useFetchDexCurrencies(chain?.dex?.tokenLists)

  const currency0 = getCurrency(wallet.chainId, token0 || '')
  const currency1 = getCurrency(wallet.chainId, token1 || '')

  const pool = useAppSelector(getPoolInfoByCurrencies(currency0, currency1))

  useEffect(() => {
    if (!pool || !chain) return

    dispatch(setBaseLiquidityData({
      currency: new Token(
        chain.chainId,
        pool.pairAddress,
        18,
        'Kirka-LP',
        'Kirka LPs',
      ),
      maxLiquidityAmount: BigNumber.from(pool.lpPooled),
    }))
  }, [dispatch, pool, chain])

  useEffect(() => {
    if (!pool) return

    const calcPerShare = (amount: BigNumber) => (
      BigNumber.from(amount).mul(BigNumber.from(percentage * 100)).div(BigNumber.from(MAX_PERCENT))
    )

    dispatch(setUpdateLiquidityData(
      (percentage)
        ? {
          liquidityAmount: calcPerShare(pool.lpPooled),
          selectedAmount0: calcPerShare(pool.pooled0),
          selectedAmount1: calcPerShare(pool.pooled1),
        }
        : {},
    ))
  }, [dispatch, pool, percentage])

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <Title currency0={pool?.currency0} currency1={pool?.currency1} />
        <DexSettingsButton />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <>
          <AmountSliderPaper
            percentage={percentage}
            setPercentage={setPercentage}
          />
          <Box pb={1}>
            <ArrowDownwardIcon color="secondary" />
          </Box>
          <ReceiveInfoPaper pool={pool} percentage={percentage} />
          <PriceInfoPaper currency0={currency0} currency1={currency1} />
          <PoolInfoPaper currency0={currency0} currency1={currency1} />
        </>
        <SmartConnectorWrapper
          checkDex
          variant="outlined"
          color="secondary"
          size="large"
        >
          <RemoveLiquidityButton />
        </SmartConnectorWrapper>
      </Box>
    </PageWrapper>
  )
}

export default RemoveLiquidity
