import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton, useTheme } from '@mui/material'

import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import {
  DexSettingsButton, SmartConnectorWrapper, TokenButton,
} from '../../buttons'
import PageWrapper from './PageWrapper'
import { useFetchDexCurrencies } from '../../../hooks'
import {
  setDexIndexer, TokenIndexer,
} from '../../../features/dex/dexSlice'
import { ModalType, openModal } from '../../../features/modal/modalSlice'
import { PoolInfoPaper } from '../../papers'
import { getChainData } from '../../../helpers/utilities'

const Title = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <IconButton color="secondary" sx={{ width: 50 }} href="#/pool">
        <ArrowBackIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
        ml={1}
      >
        <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>Import pool</Typography>
        <Typography variant="body2">Import an existing pool</Typography>
      </Box>
    </Box>
  )
}

const FindPool = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { token0, token1 } = useParams()
  const wallet = useAppSelector((state) => state.wallet)

  const chain = getChainData(wallet.chainId)
  const { getCurrency } = useFetchDexCurrencies(chain?.dex?.tokenLists)

  const currency0 = getCurrency(wallet.chainId, token0 || '')
  const currency1 = getCurrency(wallet.chainId, token1 || '')

  const handleTokenButton = (indexer: TokenIndexer) => () => {
    dispatch(setDexIndexer(indexer))
    dispatch(openModal(ModalType.SELECT_DEX_TOKEN))
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <Title />
        <DexSettingsButton />
      </Box>
      <TokenButton
        symbol={currency0?.symbol}
        logoURI={currency0?.logoURI}
        onClick={handleTokenButton(TokenIndexer.TOKEN0)}
        asEmpty
        justify
        variant="outlined"
        size="large"
      />
      <Box p={1}>
        <AddIcon />
      </Box>
      <TokenButton
        symbol={currency1?.symbol}
        logoURI={currency1?.logoURI}
        onClick={handleTokenButton(TokenIndexer.TOKEN1)}
        asEmpty
        justify
        variant="outlined"
        size="large"
      />
      <Box sx={{ marginTop: 4 }}>
        <SmartConnectorWrapper
          checkDex
          variant="outlined"
          color="secondary"
          size="large"
        >
          <PoolInfoPaper currency0={currency0} currency1={currency1} showPool />
        </SmartConnectorWrapper>
      </Box>
    </PageWrapper>
  )
}

export default FindPool
