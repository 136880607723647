import React, { useState, memo } from 'react'

import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useTheme } from '@mui/material'

import { constants } from 'ethers'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppSelector } from '../../../app/hooks'

import { ColorizedButton, DexSettingsButton, SmartConnectorWrapper } from '../../buttons'
import { getChainData } from '../../../helpers/utilities'
import PageWrapper from './PageWrapper'
import { getAccountPools, PoolInfo } from '../../../features/wallet/liquiditySlice'
import { LPHeaderBox, LPInfoBox } from '../../boxes'
import { useFetchPoolInfo } from '../../../hooks/dex'

interface LPInfoDetailProps {
  account: string
  pool: PoolInfo
  expanded: string | boolean
  setExpanded: React.Dispatch<React.SetStateAction<string | boolean>>
}

const LPInfoDetail = memo(({
  account, pool, expanded, setExpanded,
}: LPInfoDetailProps): JSX.Element => {
  const { library: provider } = useWeb3React<Web3Provider>()

  const isMining = Boolean(pool.pairAddress === constants.AddressZero)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (!isMining) {
      setExpanded(isExpanded ? panel : false)
    }
  }

  const token0 = (pool.currency0.address === constants.AddressZero) ? pool.currency0.symbol : pool.currency0.address
  const token1 = (pool.currency1.address === constants.AddressZero) ? pool.currency1.symbol : pool.currency1.address

  useFetchPoolInfo({
    provider,
    address: account,
    pool,
  })

  return (
    <Accordion
      expanded={expanded === pool.id}
      onChange={handleChange(pool.id)}
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        position: 'inherit',
        borderRadius: 4,
        '&:first-of-type': {
          borderRadius: 4,
        },
        '&:last-of-type': {
          borderRadius: 4,
        },
        marginBottom: 2,
      }}
    >
      <AccordionSummary
        expandIcon={!isMining && <ExpandMoreIcon />}
        aria-controls={`poolInfo-content-${pool.id}`}
        id={`poolInfo-header-${pool.id}`}
      >
        <LPHeaderBox
          lpPooled={pool.lpPooled}
          currency0={pool.currency0}
          currency1={pool.currency1}
          isMining={isMining}
        />
      </AccordionSummary>
      {!isMining && (
        <AccordionDetails>
          <LPInfoBox
            pairAddress={pool.pairAddress}
            lpShare={pool.lpShare}
            currency0={pool.currency0}
            pooled0={pool.pooled0}
            currency1={pool.currency1}
            pooled1={pool.pooled1}
          />
          <ButtonGroup fullWidth sx={{ marginBottom: 1 }}>
            <ColorizedButton
              variant="outlined"
              href={`#/remove/${token0}/${token1}`}
              color="secondary"
            >
              Remove
            </ColorizedButton>
            <ColorizedButton
              variant="outlined"
              href={`#/add/${token0}/${token1}`}
              color="secondary"
            >
              Add Liquidity
            </ColorizedButton>
          </ButtonGroup>
        </AccordionDetails>
      )}
    </Accordion>
  )
})

const Pool = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | boolean>(false)
  const theme = useTheme()
  const wallet = useAppSelector((state) => state.wallet)
  const pools = useAppSelector(getAccountPools)
  const chain = getChainData(wallet.chainId)

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>Your liquidity</Typography>
          <Typography variant="body2">Remove your liquidity to receive tokens back</Typography>
        </Box>
        <DexSettingsButton />
      </Box>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Box p={2} sx={{ width: '100%' }}>
          {!pools.length ? (
            <Typography variant="body2">No liquidity found</Typography>
          ) : (
            <>
              {pools.map((pool) => (
                <LPInfoDetail
                  key={pool.id}
                  account={wallet.account}
                  pool={pool}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              ))}
            </>
          )}
        </Box>
        {chain && chain.dex && (
          <>
            <Typography variant="body2" sx={{ marginBottom: 2 }}>Don`t see a pool you joined?</Typography>
            <Button variant="outlined" color="secondary" href="#/find">Find other LP tokens</Button>
          </>
        )}
      </Paper>
      <Box>
        <SmartConnectorWrapper
          checkDex
          variant="outlined"
          color="secondary"
          size="large"
        >
          <ColorizedButton
            variant="outlined"
            fullWidth
            href="#/add"
            size="large"
            color="secondary"
          >
            Add Liquidity
          </ColorizedButton>
        </SmartConnectorWrapper>
      </Box>
    </PageWrapper>
  )
}

export default Pool
