import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useAppDispatch } from '../../app/hooks'
import { ModalType, openModal } from '../../features/modal/modalSlice'
import { getChainData } from '../../helpers/utilities'
import { BridgeDirectionName, selectDirection } from '../../features/bridge/bridgeSlice'
import { ColorizedButton } from './ColorizedButton'

interface BridgeChainProps {
  chainId?: number
  direction: BridgeDirectionName
}

export const BridgeChainButton = ({ chainId, direction }: BridgeChainProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const chainData = getChainData(chainId)

  const handleClick = async () => {
    dispatch(selectDirection(direction))
    dispatch(openModal(ModalType.SELECT_BRIDGE_NETWORK))
  }
  return (
    <ColorizedButton
      size="small"
      endIcon={(chainData) ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />}
      onClick={handleClick}
      sx={{ fontSize: '0.875rem' }}
    >
      {chainData?.name ?? 'Select network'}
    </ColorizedButton>
  )
}
