import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppSelector } from '../../app/hooks'

import { prettyFormat } from '../../helpers/utilities'
import { Currency } from '../../entities'
import { useGetReserves } from '../../hooks/dex'
import { getPoolInfoByCurrencies } from '../../features/wallet/liquiditySlice'

interface PriceInfoProps {
  currency0?: Currency
  currency1?: Currency
}

const PriceInfoPaper = ({
  currency0, currency1,
}: PriceInfoProps): JSX.Element => {
  const pool = useAppSelector(getPoolInfoByCurrencies(currency0, currency1))
  const { library: provider } = useWeb3React<Web3Provider>()

  const { reserves } = useGetReserves({
    provider,
    pair: pool?.pairAddress,
  })

  // TODO: Change on some another approach more stable
  const rate0 = reserves?.reserve1
    && +prettyFormat(reserves?.reserve1, currency1?.decimals) / +prettyFormat(reserves?.reserve0, currency0?.decimals)
  const rate1 = reserves?.reserve0
    && +prettyFormat(reserves?.reserve0, currency0?.decimals) / +prettyFormat(reserves?.reserve1, currency1?.decimals)

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 2,
        marginBottom: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
        p={1}
        pl={2}
        pr={2}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          mb={1}
        >
          <Typography variant="body2" color="secondary">Prices</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2">{`1 ${currency0?.symbol} =`}</Typography>
          <Typography variant="body2" color="secondary" sx={{ fontWeight: 600 }}>
            {`${(rate0) ? +rate0.toFixed(10) : '-'} ${currency1?.symbol}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2">{`1 ${currency1?.symbol} =`}</Typography>
          <Typography variant="body2" color="secondary" sx={{ fontWeight: 600 }}>
            {`${(rate1) ? +rate1.toFixed(10) : '-'} ${currency0?.symbol}`}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default PriceInfoPaper
