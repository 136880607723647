import { SxProps, Theme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { ethers } from 'ethers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getSelectedDexToken, setDexSelectedAmount, TokenIndexer, TokenState,
} from '../../features/dex/dexSlice'

interface ButtonProps {
  indexer: TokenIndexer
  isLoading?: boolean
  sx?: SxProps<Theme>
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
  fullWidth?: boolean
}

const DexMaxButton = ({ indexer, isLoading, ...restProps }: ButtonProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const token = useAppSelector(getSelectedDexToken(indexer))
  const { maxAmount } = token as TokenState

  const disabled = isLoading || (maxAmount?.eq(ethers.constants.Zero) ?? true)

  const addMax = async () => {
    if (disabled) return
    dispatch(setDexSelectedAmount({ indexer, amount: maxAmount, update: true }))
  }

  return (
    <Button
      onClick={addMax}
      disabled={disabled}
      {...restProps}
    >
      Max
    </Button>
  )
}

export default DexMaxButton
