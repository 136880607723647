import { constants, BigNumber } from 'ethers'

import { useGetPair, useGetReserves } from '.'
import { Currency } from '../../entities'
import { MAX_PERCENT } from '../../helpers/constants'
import { quote } from '../../helpers/utilities'
import { HookProvider } from '../types'
import { pathToBridgeCurrencies } from './utils'

interface PriceImpactData {
  provider?: HookProvider
  currency0?: Currency
  currency1?: Currency
  amount0?: BigNumber
  amount1?: BigNumber
}

interface PriceImpactResponse {
  priceImpact: number
  isLoading: boolean
}

const usePriceImpact = ({
  provider, currency0, currency1, amount0, amount1,
}: PriceImpactData): PriceImpactResponse => {
  const { address: pair, isLoading: isPairLoading } = useGetPair({
    provider,
    currency0,
    currency1,
  })

  const { reserves, isLoading: isReservesLoading } = useGetReserves({
    provider,
    pair,
  })

  const currencies = pathToBridgeCurrencies(currency0, currency1)

  let [reserve0, reserve1] = [constants.Zero, constants.Zero]
  if (reserves && currencies && currency0) {
    if (currencies[0].equals(currency0)) {
      reserve0 = reserves.reserve0
      reserve1 = reserves.reserve1
    } else {
      reserve0 = reserves.reserve1
      reserve1 = reserves.reserve0
    }
  }

  if (
    reserve0.eq(constants.Zero)
    || reserve1.eq(constants.Zero)
    || !amount0
    || !amount1
    || !provider
  ) {
    return {
      priceImpact: NaN,
      isLoading: isPairLoading || isReservesLoading,
    }
  }

  const reserve0AfterSwap = reserve0.add(amount0)
  const reserve1AfterSwap = reserve1.sub(amount1)

  let beforeRatio = constants.Zero
  let afterRatio = constants.Zero
  try {
    beforeRatio = quote(amount0, reserve0, reserve1)
    afterRatio = quote(amount0, reserve0AfterSwap, reserve1AfterSwap)
  } catch (e) {
    console.log('insufficient amount')
    return {
      priceImpact: NaN,
      isLoading: isPairLoading || isReservesLoading,
    }
  }

  const increase = afterRatio.sub(beforeRatio)
  const percentage = increase.mul(MAX_PERCENT * 100).div(beforeRatio)
  const priceImpact = +(percentage.toNumber() / MAX_PERCENT).toFixed(4)

  return {
    priceImpact,
    isLoading: isPairLoading || isReservesLoading,
  }
}

export default usePriceImpact
