import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SettingsIcon from '@mui/icons-material/Settings'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getBridgeSettings, updateBridgeSettings } from '../../features/settings/settingsSlice'
import { HtmlTooltip } from '../tooltips'
import { SettingsCheckbox } from '../checkboxes'

export const BridgeSettingsButton = (): JSX.Element => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const bridgeSettings = useAppSelector(getBridgeSettings)
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev)
  }

  const handleUseExperimental = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateBridgeSettings({
      ...bridgeSettings,
      useExperimental: event.target.checked,
    }))
  }

  const handleSafeMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateBridgeSettings({
      ...bridgeSettings,
      safeMax: event.target.checked,
    }))
  }

  if (!wallet.isAuthorized) {
    return (
      <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>
        Not connected
      </Typography>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-end"
          title={(
            <Box
              p={1}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="subtitle2">Bridge Settings</Typography>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <SettingsCheckbox
                      checked={bridgeSettings.useExperimental}
                      onChange={handleUseExperimental}
                    />
                  )}
                  label={<Typography variant="body2">Use experimental feature?</Typography>}
                />
                <FormControlLabel
                  sx={{ display: 'none' }}
                  control={(
                    <SettingsCheckbox
                      checked={bridgeSettings.safeMax}
                      onChange={handleSafeMax}
                    />
                  )}
                  label={<Typography variant="body2">Safe max</Typography>}
                />
              </FormGroup>
            </Box>
          )}
        >
          <IconButton
            aria-label="settings"
            onClick={handleTooltipOpen}
            color="secondary"
          >
            <SettingsIcon />
          </IconButton>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}
