import { ethers } from 'ethers'
import {
  Ether, CKB, BNB,
} from '../entities'
import { ChainData } from './types'

export const supportedChains: ChainData[] = [
  // testnets
  {
    name: 'Rinkeby',
    shortName: 'rinkeby',
    chain: 'ETH',
    network: 'rinkeby',
    chainId: 4,
    networkId: '4',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/e043a7b3562909a3d7c7d329/eth/rinkeby',
    explorerURL: 'https://rinkeby.etherscan.io',
    isTest: true,
    nativeCurrency: new Ether(4),
    defaultTargetChainId: 71401,
    bridgeAddress: '0x9d5A617612FaA77f24FF892ce068629F94Dc72C1',
    dex: {
      provider: 'Uniswap',
      factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
      tokenLists: [
        'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
        'https://tokens.uniswap.org',
      ],
      defaultSwapParams: ['ETH', 'USDC'],
    },
    multicall2address: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
  },
  {
    name: 'GW (Test)',
    shortName: 'gw-testnet-v1',
    chain: 'CKB',
    network: 'gw-testnet',
    chainId: 71401,
    networkId: '71401',
    rpcURL: 'https://godwoken-testnet-v1.ckbapp.dev',
    explorerURL: 'https://gw-testnet-explorer.nervosdao.community',
    isTest: true,
    nativeCurrency: new CKB(71401),
    defaultTargetChainId: 4,
    bridgeAddress: '0x2454851A9320A0E11A3DD27f0d3585158eA84C94',
    dex: {
      provider: 'Kirkaswap',
      factoryAddress: '0xb61F37ec8211a33e22A29d537BDdBad5DdDc5188',
      routerAddress: '0x353D856f7092573D8814854Eac683A2b25a97521',
      tokenLists: [
        'https://raw.githubusercontent.com/kirkaswap/token-lists/master/kirkaswap-experimental.token-list.json',
      ],
      defaultSwapParams: ['pCKB', 'USDC|eth'],
    },
    multicall2address: '0x4ab68E78B94Aea17B2B77BA1eFe68Ca564238170',
  },
  {
    name: 'BSC Testnet',
    shortName: 'bsc_testnet',
    chain: 'BSC',
    network: 'bsc_testnet',
    chainId: 97,
    networkId: '97',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/e043a7b3562909a3d7c7d329/bsc/testnet',
    explorerURL: 'https://testnet.bscscan.com',
    isTest: true,
    nativeCurrency: new BNB(97),
    defaultTargetChainId: 71401,
    bridgeAddress: ethers.constants.AddressZero,
    dex: {
      provider: 'Pancakeswap',
      factoryAddress: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
      routerAddress: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
      tokenLists: [
        // eslint-disable-next-line max-len
        'https://raw.githubusercontent.com/kiemtienonline360/pancake-swap-v1-bsc-testnet/master/pancake-swap-interface-v1/src/constants/token/pancakeswap.json',
      ],
      defaultSwapParams: ['BNB', 'BUSD'],
    },
    multicall2address: '0xA6949B8FBa9DF546b9c66F98CFCa960A96E3b68e',
  },
]

export const globalTokenLists = supportedChains.reduce((list, chain) => {
  if (!chain.dex?.tokenLists) return list
  list.push(...chain.dex.tokenLists)
  return list
}, [] as string[])
