import React, { useEffect, useMemo } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Typography } from '@mui/material'
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined'

import { useAppSelector, useAppDispatch } from '../../../app/hooks'

import {
  DexSettingsButton, SmartConnectorWrapper,
} from '../../buttons'
import PageWrapper from './PageWrapper'
import { DexInput } from '../../inputs'
import { useFetchDexCurrencies } from '../../../hooks'
import { resetDexAmounts, setDexCurrency, TokenIndexer } from '../../../features/dex/dexSlice'
import { getChainData } from '../../../helpers/utilities'

interface PageDexProps {
  children: React.ReactNode[]
  separator: 'downArrow' | 'plus'
}

const PageDex = ({ children, separator }: PageDexProps): JSX.Element => {
  let { token0, token1 } = useParams()
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)

  const navigate = useNavigate()
  const location = useLocation()

  const path = location.pathname
  const [, baseRoute] = path.split('/')

  const chain = getChainData(wallet.chainId)
  const { getCurrency } = useFetchDexCurrencies(chain?.dex?.tokenLists)

  token0 = token0 || chain?.dex?.defaultSwapParams?.[0] || ''
  token1 = token1 || chain?.dex?.defaultSwapParams?.[1] || ''

  const currency0 = getCurrency(wallet.chainId, token0)
  const currency1 = getCurrency(wallet.chainId, token1)

  useEffect(() => {
    dispatch(resetDexAmounts())
    dispatch(setDexCurrency({
      indexer: TokenIndexer.TOKEN0,
      currency: currency0,
    }))
    dispatch(setDexCurrency({
      indexer: TokenIndexer.TOKEN1,
      currency: currency1,
    }))
  }, [dispatch, currency0, currency1])

  const sd = () => (
    navigate(`/${baseRoute}/${token1}/${token0}`)
  )

  const swapDirection = (token0 && token1) ? sd : undefined

  const separatorComponent = useMemo(() => {
    switch (separator) {
      case 'downArrow': {
        if (swapDirection) {
          return (
            <IconButton onClick={swapDirection}>
              <ArrowDownwardIcon />
            </IconButton>
          )
        }
        return <ArrowDownwardIcon />
      }
      case 'plus':
        return <AddIcon />
      default:
        return null
    }
  }, [separator, swapDirection])

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        {children[0]}
        <DexSettingsButton />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <CandlestickChartOutlinedIcon />
        <Typography variant="body2">Liquidity provider:</Typography>
        <Typography
          variant="body2"
          sx={
            (theme) => ({ color: theme.palette.secondary.main, marginLeft: 0.5 })
          }
        >
          {chain?.dex?.provider || '-'}
        </Typography>
      </Box>
      <DexInput indexer={TokenIndexer.TOKEN0} />
      <Box p={1}>
        {separatorComponent}
      </Box>
      <DexInput indexer={TokenIndexer.TOKEN1} />
      <Box sx={{ marginTop: 4 }}>
        <SmartConnectorWrapper
          checkDex
          variant="outlined"
          color="secondary"
          size="large"
        >
          {children[1]}
        </SmartConnectorWrapper>
      </Box>
    </PageWrapper>
  )
}

export default PageDex
