import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { BigNumber } from 'ethers'

import { getTokenLogoURI, prettyFormat } from '../../helpers/utilities'
import { PoolInfo } from '../../features/wallet/liquiditySlice'
import ImgIcon from '../icons/ImgIcon'
import { MAX_PERCENT } from '../../helpers/constants'

interface ReceiveInfoProps {
  pool?: PoolInfo
  percentage: number
}

const ReceiveInfoPaper = ({
  pool, percentage,
}: ReceiveInfoProps): JSX.Element => {
  const {
    currency0, currency1, pooled0, pooled1,
  } = pool || {}

  // TODO: Fix this
  const poolPer0 = pooled0
    && currency0
    && BigNumber.from(pooled0).mul(BigNumber.from(percentage * 100)).div(BigNumber.from(MAX_PERCENT))

  const poolPer1 = pooled1
    && currency1
    && BigNumber.from(pooled1).mul(BigNumber.from(percentage * 100)).div(BigNumber.from(MAX_PERCENT))

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 2,
        marginBottom: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
        p={1}
        pl={2}
        pr={2}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          mb={1}
        >
          <Typography variant="body2" color="secondary">You will receive</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            mb={1}
          >
            {currency0 && (
              <ImgIcon src={getTokenLogoURI(currency0.symbol)} />
            )}
            <Typography variant="body2" sx={{ marginLeft: 1 }}>{currency0?.symbol || '-'}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mb={1}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {(currency0 && poolPer0) ? prettyFormat(poolPer0, currency0.decimals) : '-'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {currency1 && (
              <ImgIcon src={getTokenLogoURI(currency1.symbol)} />
            )}
            <Typography variant="body2" sx={{ marginLeft: 1 }}>{currency1?.symbol || '-'}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {(currency1 && poolPer1) ? prettyFormat(poolPer1, currency1.decimals) : '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default ReceiveInfoPaper
