import { useEffect } from 'react'
import { SxProps, Theme } from '@mui/material'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColorizedButton } from './ColorizedButton'

import useERC20Approve from '../../hooks/useERC20Approve'
import {
  getSelectedDexToken, setDexApproval, TokenIndexer, TokenState,
} from '../../features/dex/dexSlice'
import { useSimpleCheckAllowance } from '../../hooks'

interface ButtonProps {
  indexer: TokenIndexer
  contract?: string
  fullWidth?: boolean
  sx?: SxProps<Theme>
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const ApproveButton = ({
  indexer, contract, sx, ...props
}: ButtonProps): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useAppSelector((state) => state.wallet)
  const token = useAppSelector(getSelectedDexToken(indexer))
  const { currency, selectedAmount } = token as TokenState
  const { library: provider } = useWeb3React<Web3Provider>()
  const {
    isAllowed, isRequired, isLoading,
  } = useSimpleCheckAllowance({
    provider,
    contract,
    currency,
    account,
    amount: selectedAmount,
  })
  const { approve, isApproved, isLoading: isApproveLoading } = useERC20Approve({
    provider,
    chainId,
    account,
    contract: currency?.address,
    addressToApprove: (isRequired) ? contract : undefined,
  })

  useEffect(() => {
    dispatch(setDexApproval({
      indexer,
      approved: isAllowed,
    }))
  }, [dispatch, indexer, isAllowed])

  if (!currency || !isRequired) {
    return null
  }

  const loading = isLoading || isApproveLoading

  const handleClick = async () => {
    if (!isRequired) return
    await approve(chainId)
  }

  return (
    <ColorizedButton
      disabled={!isRequired || isAllowed || isApproved || !selectedAmount}
      loading={loading}
      onClick={handleClick}
      sx={sx}
      {...props}
    >
      {isAllowed ? `Approved ${currency?.symbol}` : `Approve ${currency?.symbol}`}
    </ColorizedButton>
  )
}

export default ApproveButton
