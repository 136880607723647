import React, { cloneElement } from 'react'

import Button from '@mui/material/Button'

import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ConnectButton } from './ConnectButton'
import { ColorizedButton } from './ColorizedButton'
import { getChainData } from '../../helpers/utilities'
import { ModalType, openModal } from '../../features/modal/modalSlice'

interface DappWrapperProps {
  checkDex?: boolean
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
  fullWidth?: boolean
  customProps?: boolean
  availableChains?: number[]
}

const SmartConnectorWrapper = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkDex, children, customProps, availableChains, fullWidth = true, ...otherProps
}: DappWrapperProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const { error } = useWeb3React<Web3Provider>()
  const chain = getChainData(wallet.chainId)
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  const handleNotSupportedDex = () => dispatch(openModal(ModalType.SELECT_DEX_NETWORK))

  if (isUnsupportedChainIdError) {
    return (
      <ColorizedButton
        fullWidth={fullWidth}
        disabled
        {...otherProps}
      >
        Unsupported network
      </ColorizedButton>
    )
  }
  if (!wallet.isAuthorized) {
    return <ConnectButton fullWidth={fullWidth} {...otherProps} />
  }
  if (!chain || (checkDex && !chain.dex)) {
    return (
      <Button onClick={handleNotSupportedDex} fullWidth={fullWidth} {...otherProps}>
        Select available network
      </Button>
    )
  }
  if (customProps) {
    return cloneElement(children as JSX.Element)
  }
  return cloneElement(children as JSX.Element, { fullWidth, ...otherProps })
}

export default SmartConnectorWrapper
