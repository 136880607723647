import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SettingsIcon from '@mui/icons-material/Settings'
import FormGroup from '@mui/material/FormGroup'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useTheme } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getDexSettings, updateDexSettings } from '../../features/settings/settingsSlice'
import { HtmlTooltip } from '../tooltips'
import { MAX_PERCENT, PERCENT_DENOMINATOR } from '../../helpers/constants'

export const DexSettingsButton = (): JSX.Element => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const dexSettings = useAppSelector(getDexSettings)
  const wallet = useAppSelector((state) => state.wallet)
  const [open, setOpen] = useState(false)

  const [slippage, setSlippage] = useState<number | undefined>(dexSettings.slippage)
  const emptySlippage = 0.1 * PERCENT_DENOMINATOR

  useEffect(() => {
    if (
      dexSettings.slippage === slippage
      || (!slippage && dexSettings.slippage === emptySlippage)
    ) return

    dispatch(updateDexSettings({
      ...dexSettings,
      slippage: slippage ?? emptySlippage,
    }))
  }, [dispatch, dexSettings, emptySlippage, slippage])

  const handleTooltipClose = () => {
    setOpen(false)
    setSlippage(dexSettings.slippage)
  }

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev)
  }

  const prettifyPercent = (value: number) => (
    +(value / PERCENT_DENOMINATOR).toFixed(2)
  )

  const handleValue = (value: string): number | undefined => {
    const newValue = +value >= 0 ? value : ''

    if (newValue.includes('-') || newValue.length > 32) return undefined

    const splitedV = newValue.split('.')[1]
    if (splitedV && splitedV.length > 18) return undefined

    return +newValue
  }

  const handleSlippage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = handleValue(event.target.value)

    if (value && +value * PERCENT_DENOMINATOR > MAX_PERCENT) {
      return
    }

    setSlippage((value) ? +value * PERCENT_DENOMINATOR : undefined)
  }

  const handleDeadline = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = handleValue(event.target.value)

    dispatch(updateDexSettings({
      ...dexSettings,
      deadline: (value) ? +value : 0,
    }))
  }

  if (!wallet.isAuthorized) {
    return (
      <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>
        Not connected
      </Typography>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-end"
          title={(
            <Box
              p={2}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="subtitle2" gutterBottom>DEX Settings</Typography>
              <FormGroup
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginBottom: 1,
                }}
              >
                <InputLabel
                  htmlFor="slippage"
                  sx={{ marginBottom: 1, fontSize: 12 }}
                >
                  Slippage tolerance (%) ?
                </InputLabel>
                <OutlinedInput
                  id="slippage"
                  color="secondary"
                  sx={{ borderRadius: 4 }}
                  value={(slippage) ? prettifyPercent(slippage) : undefined}
                  onChange={handleSlippage}
                  type="number"
                />
              </FormGroup>
              <FormGroup
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginBottom: 1,
                }}
              >
                <InputLabel
                  htmlFor="deadline"
                  sx={{ marginBottom: 1, fontSize: 12 }}
                >
                  Transaction deadline (minutes) ?
                </InputLabel>
                <OutlinedInput
                  id="deadline"
                  color="secondary"
                  sx={{ borderRadius: 4 }}
                  value={dexSettings.deadline}
                  onChange={handleDeadline}
                  type="number"
                />
              </FormGroup>
            </Box>
          )}
        >
          <IconButton
            aria-label="settings"
            onClick={handleTooltipOpen}
            color="secondary"
          >
            <SettingsIcon />
          </IconButton>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}
